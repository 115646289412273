import { Component } from '@angular/core';
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { ErrorHandelingService } from '../_services/error-handeling.service';

@Component({
    selector: 'display-global-errors-component',
    templateUrl: './display-global-errors.component.html'
})

export class DisplayGlobalErrorsComponent {

    faExclamation = faExclamation;

    constructor(
        public errorHandelingService: ErrorHandelingService
    ) { }
}