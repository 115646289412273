import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { ErrorsConvertService } from '../../shared/_services/errors-convert.service';
import { ApiError } from '../../shared/_models/api-error.model';
import { DisplayErrors } from '../_models/display-errors.model';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandelingService {
    private components: DisplayErrors[] = [];
    public globalErrors: string[] = [];

    constructor(
        private errorConvertService: ErrorsConvertService,
        private readonly router: Router
    ) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.reset();
            }
        });
    }

    public reset() {
        this.clearErrors();
        this.clearComponents();
    }

    public setErrors(errors: ApiError[]) {
        this.clearErrors();
        for (let i = 0; i < errors.length; i++) {
            let error = errors[i];

            if (!error.name) {
                error.name = '';
            }

            let component = this.components.find((comp) => comp.name.toUpperCase() == error.name.toUpperCase());
            if (component != null) {
                component.addError(this.errorConvertService.toMessage(error, component.label));
            } else {
                this.globalErrors.push(this.errorConvertService.toMessage(error))
            }
        }
    }

    public register(component: DisplayErrors) {
        this.components.push(component);
    }

    public clearErrors() {
        this.globalErrors.length = 0;
        for (let i = 0; i < this.components.length; i++) {
            this.components[i].clearErrors();
        }
    }

    private clearComponents() {
        this.components.length = 0;
    }
}