import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ErrorHandelingService } from '../_services/error-handeling.service';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { DisplayErrors } from '../_models/display-errors.model';

@Component({
    selector: 'display-errors-component',
    templateUrl: './display-errors.component.html'
})

export class DisplayErrorsComponent implements OnInit, DisplayErrors {

    @Input()
    public name: string;

    @Input()
    public label: string;

    public errors: string[] = [];

    faExclamation = faExclamation;

    constructor(
        private errorHandelingService: ErrorHandelingService
    ) { }

    public ngOnInit(): void {
        if (!this.name) {
            throw new Error('name is niet ingevuld')
        }
        if (!this.label) {
            throw new Error('label is niet ingevuld')
        }

        this.errorHandelingService.register(this);
    }

    public addError(message: string) {
        this.errors.push(message);
    }

    public clearErrors() {
        this.errors.length = 0;
    }
}